export const booking = {
    common: {
        timer: {
            processing:'Бронирование истечет через 20 минут, и процесс нужно будет повторить.',
            over: 'Бронирование истекло',
            title: 'Бронирование',
        },
        info_titles: {
            location: 'Локация',
            floor: 'Этаж',
            finishing: 'Отделка',
            realty_price: 'Стоимость объекта',
            booking_price: 'Стоимость бронирования',
            purchase_condition: 'Условия покупки',
            booking_period: 'Срок бронирования',
        },
    },
    client: {
        purchase_conditions: {
            title: 'Выбор способа покупки',
        },
        personal_data: {
            title: 'Введите личные данные',
            text: 'Для оформления договора нужны ваши данные и почта для отправки копии. Убедитесь, что указали данные владельца квартиры.',
            form: {
                emailInfo: 'На эту почту придет подтверждение бронирования',
            },
        },
        offer: {
            title: 'Договор оферты',
        },
        payment: {
            title: 'Оплата бронирования',
        },
        finishing: {
            success: {
                title: 'Оплата прошла успешно',
            },
            fail: {
                title: 'Ошибка оплаты',
            },
        },
        bookings: {
            title: 'Ваши Бронирования',
            list: {
                empty: 'Список объектов пока пуст',
                emptyDescription: 'После бронирования здесь появятся все ваши объекты',
                toRealtyCatalog: 'Перейти в каталог',
            },
            statuses: {
                in_process: 'В процессе',
                booked: 'Забронировано до',
                awaiting_confirmation: 'Ожидает подтверждения',
            },
            button: {
                information: 'Информация',
                continue: 'Продолжить',
                awaiting_confirm: 'Ожидает подтверждения',
            },
        },
        summary: {
            title: 'Ваше бронирование',
            info: {
                booking: 'Бронирование',
                location: 'Локация',
                realty_info: 'Информация об объекте', 
                titles: {
                    booking_price: 'Стоимость бронирования',
                    term: 'Срок',
                },
            },
            download_offer: 'Скачать договор оферты',
        },
    },
    admin: {},

};
