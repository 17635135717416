export const coupon = {
    category: {
        title : 'Категории партнеров',
        list:{
            title : 'Список категорий',
            name: 'Название',
            add: 'Добавить',
            edit: 'Редактировать'
        },
        card: {
            title: 'Категория',
            name: 'Название',
            cancel: 'Отменить',
            save: 'Сохранить'
        },
        shortlist: {
            all: 'Все'
        }
    },
    partners: {
        title: 'Партнеры',
        link: 'Ссылка',
        linkName: 'Текст ссылки',
        promoCode: 'Промокод',
        certificate: 'Сертификат',
        getDiscount: 'Получить',
        list: {
            create: {
                add: 'Добавить',
                cancel: 'Отменить',
                addPartner: 'Добавление партнера'
            },
        },
        card: {
            name: 'Название',
            textLogo: 'Заголовок вместо логотипа',
            price: 'Стоимость',
            offer: 'Текст в кнопке',
            active: 'Активен',
            category: 'Категория',
            underLogoCard: 'Подзаголовок',
            textUnderButton: 'Текст под кнопкой',
            description: 'Описание партнера',
            conditions: 'Описание условий',
            howToGet: 'Как получить',
            phone: 'Телефон',
            site: 'Сайт',
            promocodeDescription: 'Описание промокода',
            selectLinkType: 'Выберите тип',
            downloadCertificate: 'Скачать сертификат',
            uploadCertificate: 'Загрузить сертификат',
            deleteCertificate: 'Удалить сертификат',
            cancel: 'Отменить',
            save: 'Сохранить',
            getPromocode: 'Получить промокод',
            getLink: 'Получить ссылку',
            getDiscount: 'Получить скидку',
            getCertificate: 'Получить сертификат',
            activatePartner: 'Партнер успешно активирован',
            deactivatePartner: 'Партнер успешно деактивирован',
            areYouSureActivatePartner: 'Вы уверены, что хотите активировать партнера?',
            areYouSureDeactivatePartner: 'Вы уверены, что хотите деактивировать партнера?'
        }
    }
};