export const booking = {
    common: {
        timer: {
            processing:'The booking will expire in 20 minutes and the process will need to be repeated.',
            over: 'The booking has expired',
            title: 'Booking',
        },
        info_titles: {
            location: 'Location',
            floor: 'Floor',
            finishing: 'Finishing',
            apartment_price: 'The cost of the realty',
            booking_price: 'The cost of booking',
            purchaseTerms: 'Terms of purchase',
            booking_period: 'Booking period',
        },
    },
    client: {
        purchase_conditions: {
            title: 'Choosing a purchase method',
        },
        personal_data: {
            title: 'Enter your personal information',
            text: 'To formalize the contract, we need your data and mail to send a copy. Make sure that you have entered the details of the apartment owner.',
            form: {
                emailInfo: 'A booking confirmation will be sent to this email',
            },
        },
        offer: {
            title: 'The offer Agreement',
        },
        payment: {
            title: 'Payment for the booking',
        },
        finishing: {
            success: {
                title: 'The payment was successful',
            },
            fail: {
                title: 'Payment error',
            },
        },
        bookings: {
            title: 'Your booking',
            list: {
                empty: 'The list of objects is currently empty',
                emptyDescription: 'After the purchase, all your objects will appear here',
                toRealtyCatalog: 'Go to the catalog',
            },
            statuses: {
                in_process: 'In process',
                booked: 'Booked until',
                awaiting_confirmation: 'Awaiting confirmation',
            },
            button: {
                information: 'Information',
                continue: 'Continue',
            },
        },
        summary: {
            title: 'Your booking',
            info: {
                booking: 'Booking',
                location: 'Location',
                realty_info: 'Information about the obtjec', 
                titles: {
                    booking_price: 'The cost of booking',
                    term: 'Term',
                },
            },
            download_offer: 'Download the offer agreement',
        },
    },
    admin: {},
};
