export const users = {
    list: {
        title: 'Users',
        dialog: {
            coupons: {
                title: 'Coupons',
                close: 'Close',
                table: {
                    headers:{
                        date: 'Date',
                        partner: 'Partner',
                        product: 'Product'
                    }
                }
            }
        },
        table: {
            name: 'FIO',
            lastName:'Surname ',
            firstName:'First name',
            phoneNumber: 'Phone number',
            email: 'Email',
            role: 'Role',
            coupons: 'Coupons',
            createDate: 'Registration date',
            action: 'Action',
            viewUser: 'Edit',
            blockUser: 'Block',
            unblockUser: 'Unblock',
            notAllowedBlockYourself: 'You can\'t block yourself',
            notAllowedUnBlockYourself: 'You can\'t unblock yourself'
        },
        filter: {
            search: 'Search...',
            isEnabled: 'Active',
            find: 'Find',
            clear: 'Clear'
        }
    },
    card: {
        title: 'Personal information',
        lastName: 'Last name',
        firstName: 'First name',
        birthDate:'Date of birth',
        email: 'Email',
        emailVerify: 'Verify',
        phoneNumber: 'Phone number',
        block: 'Block',
        unblock: 'Unblock',
        role: 'Role',
        createDate: 'Registration date',
        changeRole: 'Change role',
        save: 'Save',
        forSendingIntrestingNews: 'The most interesting offers will be sent there',
        uniqueIdentifier: 'Unique identifier',
        uniqueIdentifierNotChange: 'Unique identifier, you can\'t change this',
        selfRoleChangingError: 'You cannot change the role to yourself',
        allowChangeEmailOnceIn3Minutes: 'Changing email is allowed only once every 3 minutes.',
        emailVerifyMessageSent: 'Email confirmation email sent'
    }
};