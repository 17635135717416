export const coupon = {
    category: {
        title : 'Categories of partners',
        table:{
            title : 'Categories',
            name: 'Title',
            add: 'Add',
            edit: 'Edit'
        },
        card: {
            title: 'Category',
            name: 'Title',
            cancel: 'Cancel',
            save: 'Save'
        },
        shortlist: {
            all: 'All'
        }
    },
    partners: {
        title: 'Partners',
        link: 'Link',
        linkName: 'Text of link',
        promoCode: 'PromoCode',
        certificate: 'Certificate',
        getDiscount: 'Get',
        list: {
            create: {
                add: 'Add',
                cancel: 'Cancel',
                addPartner: 'Add partner'
            },
        },
        card: {
            name: 'Name',
            textLogo: 'Title instead of logo',
            price: 'Price',
            offer: 'Text in button',
            active: 'Active',
            category: 'Category',
            underLogoCard: 'Subtitle',
            textUnderButton: 'Text under button',
            description: 'Description',
            conditions: 'Conditions',
            howToGet: 'How to get',
            phone: 'Phone',
            site: 'Site',
            promocodeDescription: 'Promocode description',
            selectLinkType: 'Choose a type',
            downloadCertificate: 'Download certificate',
            uploadCertificate: 'Upload certificate',
            deleteCertificate: 'Delete certificate',
            cancel: 'Cancel',
            save: 'Save',
            getPromocode: 'Get a promo code',
            getLink: 'Get a link',
            getDiscount: 'Get a discount',
            getCertificate: 'Get a certificate',
            activatePartner: 'Partner successfully activated',
            deactivatePartner: 'Partner successfully deactivated',
            areYouSureActivatePartner: 'Are you sure you want to activate the partner?',
            areYouSureDeactivatePartner: 'Are you sure you want to deactivate the partner?'
        }
    }
};